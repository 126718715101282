import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function TopBarProfile(props) {

  const patient = props.patient;
  let history = useHistory();
  var random = getRandomColor()


  function logOut() {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  function changeProfile() {
    history.push('/change-patient' , { patient: patient });
  }

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") 
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) 
      .join("");
  };

  function getRandomColor() {

    const bgColors = ['#7B7BFF', '#f66292', '#ffba24', '#00dd73', '#ff8064'];
  
    var color =  bgColors[Math.floor(Math.random() * 6)];
    color = color === undefined ? bgColors[0] : color
    return color;
  }

  
  return (
    <React.Fragment>
      <div className='top-bar'>
        <div className='organization-info'> {patient.organization_name}</div>
        <div className='patient-info'>

          {patient.avatar_url_large?<img src={patient.avatar_url_large} alt='profile' className='img-profile'/>
           :<div className='initials' style={{backgroundColor: random}}>{getInitials(patient.full_name)}</div> 
          }
        
          <NavDropdown title={patient.full_name} id="basic-nav-dropdown" className='nav-name'>
            <NavDropdown.Item onClick={changeProfile}>Change patient</NavDropdown.Item>
            <NavDropdown.Item onClick={logOut}>log out</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
      <style jsx global>{` 
        .top-bar .img-profile{
          margin-right: 0px;
        }
        .top-bar .nav-link{
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        .top-bar{
          background-color: white;
          padding: 20px 0px 12px 0px;
          display: flex;
          justify-content: space-between;
          box-shadow: 0 2px 4px 0 #c3cbd1;

        }
        .top-bar .patient-info{
          float: right;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
          margin-right: 37px;
          display: flex;
        }
        .top-bar .organization-info{
          margin-left: 41px;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
        }
        .top-bar img{
          border-radius: 50%;
          background-color: #f66292;
          width: 35px;
          height: 35px;
          margin-right: 16px;
        }

        .initials{
          border-radius: 50%;
          width: 35px;
          height: 35px;
          text-align: center;
          color: white;
          padding-top: 10px;
        }
      `}
      </style>
    </React.Fragment>
  );
}
export default TopBarProfile;