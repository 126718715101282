import React from 'react';
import { useTranslation } from 'react-i18next';

function Help() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="forms-header">
        {t('login-form.having_trouble')}<span className="get-help"> {t('login-form.get_help')}</span>
      </div> 
      <style jsx>{`
          .forms-header {
            width: 100%;
            margin-top: 50px;
            text-align: right;
            font-size: 15px;
            margin-right: 10px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--slate);
          }
          
          .get-help {
            font-weight: bold;
            color: var(--deep-sky-blue);
          }
        
      `}</style>
    </React.Fragment>
     
  );
}

export default Help;


