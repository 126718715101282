import  {getConsultations} from '../services/Api'
import React from "react";
import Empty from './Empty'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation,withTranslation } from 'react-i18next';

function ConsultationsRecord(props){
  const {t} = useTranslation()
  const data = props.data;
  var random = getRandomColor()
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") 
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) 
      .join("");
  };

  function getRandomColor() {

    const bgColors = ['#7B7BFF', '#f66292', '#ffba24', '#00dd73', '#ff8064'];
  
    var color =  bgColors[Math.floor(Math.random() * 6)];
    color = color === undefined ? bgColors[0] : color
    return color;
  }
  return(
    <div className='record col-10 col-s-12 '>
      <div className='consultation-card row'>
        <div className='rectangle' style={{backgroundColor: random}}/>
        <div className='info'>
          <div className='initials' style={{backgroundColor: random}}>{getInitials(data.doctor_name)}</div> 

          <div className='doc-info' > 
            <span className='doc-name'>  {data.doctor_name}</span>
            <span className='doc-name'>  {data.doctor_speciality}</span>
            <span className='org-name'>{data.organization_name}</span>
          </div>

          <div className='consultation-info'> 
            <span className='cause'>{data.cause}  </span>
          </div>
          
          <div className='date'>
            <span >{formatDate(data.starts_at)} </span>
          </div>

          <div className='consultation-info'> 
            <span className='btn'>{t('consultations.see-more')}  </span>
          </div>
          
        </div>
        
      </div>  
      <style jsx='true' >{` 
        .rectangle {
          width: 4px;
          height: auto;
        }
        .date{
          margin: 33px 51px 37px 43px;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 18px;
          color: var(--steel);
        }
        .cause{
          padding: 3px 6px 4px 8px;
          border-radius: 11px;
          background-color: #d9ebff;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #0176fe;
          margin-left: 51px;
          margin-right: 43px;
        }
        .btn{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          background-color: var(--deep-sky-blue);
          color: white;
        }
        .record{
          padding-left: 0px;
          margin-left: 15px;

        }
        .doc-info{
          margin-left: 15px;
        }
        .consultation-info{
          margin-left: 15px;
          margin-top: 15px;
        }
        .consultation-card{
          border-radius: 4px;
          border: solid 1px var(--light-sky-blue);
          background-color: var(--white);
          display: inline-flex;
          padding-down: 30px;
          width: 100%;
        }
        .org-name{
          display: block;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
          margin-top: 7px;
          margin-bottom: 7px;
          
        }
        .doc-name{
          display: block;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
          margin-top: 7px;
          margin-bottom: 7px;
        }
        img{
          align-self: center;
        }
    
        .info{
          padding-left: 16px;
          width: 100%;
          display: inline-flex;
          padding-top: 12px;
        }
        .initials{
          border-radius: 50%;
          width: 55px;
          height: 55px;
          text-align: center;
          color: white;
          padding-top: 18px;
        }
        .row{
          flex-wrap: nowrap;
        }

      `}</style>
    </div>
  )
}

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

class Consultations extends React.Component {
  
  
  constructor(props) {
    super(props);
    
    this.state = {
      consultations: [],
      hasMore: true,
      patient: props.patient_id,
      page: 1,
      empty: true
    };
    
  }

  componentDidMount() {
    this.getData()   
  }

 async getData(){
  
    if ( this.state && this.state.hasMore){
      try {
        const response = await getConsultations(this.state.patient, this.state.page);

        if(response.request.status === 200 && response.data.consultations){
          
          const elements = response.data.consultations;          
          if(Object.entries(elements).length !== 0){
            this.setState({empty: false})
            if(this.state.page > 1){
              this.setState({consultations: this.state.consultations.concat(elements)});
            }
            else{
              this.setState({consultations: elements});
              
            }
          }
          else{
            this.setState({empty: true})
          }	          
          if(this.state.page < response.data.meta.total_pages){
            var p = this.state.page + 1
            this.setState({page: p})
            this.setState({hasMore: true})
            
          }
          else{
            this.setState({hasMore: false})
          }
          
        }else{
        
        }
      }catch (e) {
           
        console.warn("ERROR", e);
        
      }
    }
    

  }
  drawRecord(element){
    return (<ConsultationsRecord data={element}  key={element.id}></ConsultationsRecord>)
  }

 render() { 
    const { t } = this.props;
    return (
      <div>
        <div className='title-th'> {t('consultations.title')} </div>
        <Empty empty={this.state.empty}/>
        <InfiniteScroll
          dataLength={this.state.consultations.length}
          next={this.getData.bind(this)}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}

        >
          {this.state.consultations.map((i, index) => (
             this.drawRecord(i)
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}
export default withTranslation()(Consultations)