import React, { useState } from "react";
import  {codeValidation} from "../services/Api"
import { useTranslation } from 'react-i18next';
import useLocalStorage from "./../hook/useLocalStorage";
import Help from './Help';
import Check from '../assets/images/green-check.svg'
import Loading from '../components/LoadingIndicator'

function ValidationForm(props) {
  const { t } = useTranslation();
  const [phone, _p] = useLocalStorage("PHONE_KEY", "");
  const [countryCode, _c] = useLocalStorage("COUNTRY_CODE_KEY", "52");
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondtDigit] = useState("");
  const [thirdhDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [showCheck, setShowCheck] = useState(false)
  const [loading, setLoading] = useState(false);

 async function requestCode(event){
    event.preventDefault();
    setLoading(true);
    const code = firstDigit + secondDigit + thirdhDigit + fourthDigit;
     const data = {
      code: code,
      country_code: countryCode,
      phone
    };
   
    const response = await codeValidation(data);
    if(response.request.status === 200){ 
      localStorage.setItem("token", response.data.token);
      setLoading(false);
      window.location.href = "/patients";
    }else{
      setLoading(false);
      window.location.href = "/no-user";
    }
     
  }

  function setFocus(e){
    e.preventDefault();
    var txtFocus = 'txt-1';
  
    if(e.target.id === 'txt-1')
    {
      txtFocus = 'txt-2';
    }
    else if (e.target.id === 'txt-2') {
      txtFocus = 'txt-3';
    }
    else if (e.target.id === 'txt-3') {
      txtFocus = 'txt-4';
    }
 
    var txt = document.getElementById(txtFocus);
    txt.focus();
  }
  return (
    <React.Fragment>
      
      <div className="forms">
        <Help /> 
        <div className="formBody">
          <h1> {t('validation-form.title')}</h1>
          <h4>  {t('validation-form.description')}<span className='phone'>+{countryCode + phone}</span></h4>
          <form className='formBox'>
            <input
              type="text" 
              placeholder="0"
              maxLength="1"
              id='txt-1'
              onChange={e => {
                setFirstDigit(e.target.value);
                setFocus(e);
              
              }}
            />
            <input
              type="text" 
              placeholder="0"
              maxLength="1"
              id='txt-2'
              onChange={e => {
                setSecondtDigit(e.target.value);
                setFocus(e);
              }}
            />
            <input
              type="text" 
              placeholder="0"
              maxLength="1"
              id='txt-3'
              onChange={e => {
                setThirdDigit(e.target.value);
                setFocus(e);
              }}
            />
            <input
              type="text" 
              placeholder="0"
              maxLength="1"
              id='txt-4'
              onChange={e => {
                setFourthDigit(e.target.value);
                e.target.value !== '' ? setShowCheck(true) : setShowCheck(false);
                
              }}
            />
            { showCheck ?  <img src={Check} alt="Logo" className='icon'/> : null }
            <Loading working={loading}/>
            
            <div className='actions'>
              {/* <button className='backBtn'>Back</button>  */}
              <button className='sendBtn' onClick={requestCode}>Next</button> 
              {/* <input className='submit' type="submit" value="Submit" />        */}
            </div>
          
          </form>
        </div>
      </div>
      <style jsx>{`
          .actions {
            margin-top: 195px;
          }
           h1 {
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            
          }
          h2 {
            font-family: Proxima Nova;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color:  #76869e;
          }
  
          .phone {
            color: var(--deep-sky-blue);
            font-weight: 600;
          }
          input{
            margin: 10px 26px 10px 0px;
            padding: 1rem;            
            width: 54px;
            height: 54px;
            font-size: 20px;
            text-align: center;
          }
          .formBox {
            // display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            margin-top: 76px;
          }
          .sendBtn{
            border-radius: 4px;
            background-color: var(--deep-sky-blue);
            border-color: var(--deep-sky-blue);
            color: white;
            font-weight: 700;
            padding: initial;
            margin-bottom: 1rem;
            font-size: 0.8rem;
            position: absolute;
            right: 0px;
            width: 118px;
            height: 36px;
            border-radius: 4px;
             
          }
          button{
            padding: initial;
            margin-right: 10px;
            background: gray;
            border-color: gray;
            border-radius: 3px;
            color: white;
            font-weight: 700;
            width: 20%;
            margin-bottom: 1rem;
            font-size: 0.8rem;
            width: 118px;
            height: 36px;
            border-radius: 4px;
            
          }
          .forms{
            margin-top: 5%;
          }

          @media only screen and (min-width: 768px) { 
            .forms {             
              margin-left: 60px;
              margin-right: 30px;
              
            }
  
            .formBody{
              padding-top: 4%;
              width: 60%;
            }
          }
          h1 {
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--dark-blue-grey);
          }
          h4 {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: var(--slate);
          }
        `}</style>
    </React.Fragment>
  );
}
export default ValidationForm

