import React from 'react';

function Empty(props) {
  var empty = props.empty;
  
  return (
    <React.Fragment>
      {empty ? <h1> no data </h1> : null}
    </React.Fragment>
     
  );
}

export default Empty;


